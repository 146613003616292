import { Container } from "./styles";
import externalLinkIcon from "../../assets/external-link-icon.svg";
import appleIcon from "../../assets/apple-icon.svg";
import huaweiIcon from "../../assets/huawei-icon.svg";
import androidIcon from "../../assets/android-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function Portfolio() {
  return (
    <Container id="portfolio">
      <h2>My portfolio</h2>

      <div className="projects">
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://apps.apple.com/us/app/sohatidoc/id6445897076"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sohati.sohatidoc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>SohatiDoc - Mobile Application (DIEM)</h3>
              <p>
                Sohatidoc is an online clinic that provides telemedicine
                consultations with doctors in various fields.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Native</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://oneautocar.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="Visit site" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/one-autocar-all-deals-oneplace/id1629835090"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.oneautocar"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>One AutoCar - Wesbite & Mobile Application</h3>
              <p>At One AutoCar, you can find all deals in one place.</p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>MySQL</li>
                <li>Laravel</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
            </header>
            <div className="body">
              <h3>Mercado (Pending public release)</h3>
              <p>
                Mercado brings the best of your local coffee shop and market to
                your fingertips. Seamlessly place orders, track deliveries in
                real-time, and enjoy a personalized shopping experience from the
                comfort of your home. With Mercado, fresh products, delicious
                brews, and quick service are just a tap away.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Native</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
            </header>
            <div className="body">
              <h3>Kardoflix (Pending public release)</h3>
              <p>
                Kardoflix brings the magic of movies and series straight to your
                screen, offering a vast library of content for every taste. From
                blockbuster hits to hidden gems, stream your favorite shows and
                discover new favorites with a seamless, user-friendly
                experience. Kardoflix makes entertainment easy, anytime,
                anywhere.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Native</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://play.google.com/store/apps/details?id=com.eliechaaban.coopleb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>CoopLeb - Directorate of COOPs</h3>
              <p>
                CoopLeb is the ultimate solution for co-ops, offering intuitive
                connectivity, real-time news updates, vibrant community
                engagement, and dedicated support. With CoopLeb, connecting,
                collaborating, and thriving has never been easier.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Native</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://apps.apple.com/us/app/local-meals/id6596768968"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.oml.localmeals"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Local Meals</h3>
              <p>
                Designed for both locals and visitors, the platform allows local
                vendors and artisans to showcase their food, culture, and
                services through beautiful photo galleries and detailed
                descriptions. Visitors can explore these offerings, learn about
                the rich cultural heritage, and use the integrated map feature
                to find and navigate to local service providers easily.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>MySQL</li>
                <li>Laravel</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
            </header>
            <div className="body">
              <h3>3a Tari2na - Wesbite & Mobile Application</h3>
              <p>Explore F&B spots on your way</p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>TailwindCSS</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://apps.apple.com/lb/app/gold-squad/id1659484576"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.eliechaaban.goldsquad"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Gold Squad - Website & Mobile Application</h3>
              <p>
                Gold Squad Time Tracking is a cloud-based time tracking and
                scheduling app that allows your employees to clock in and out
                from the job site with the devices they've already got in their
                pockets.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://apps.apple.com/us/app/businesspackhr/id1610915628"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.eliechaaban.BusinessPackHR"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>BusinessPackHR - Wesbite & Mobile Application</h3>
              <p>
                BusinessPackHR is online HR software for small and medium-sized
                businesses that have outgrown spreadsheets. We automate many of
                the most tedious HR functions and free you up to do meaningful
                work. The BusinessPackHR app lets you take some of those most
                important functions with you, and makes your most common tasks
                in BusinessPackHR simple and fast.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://homecut.app/" target="_blank" rel="noreferrer">
                  <img src={externalLinkIcon} alt="Visit site" />
                </a>
                <a
                  href="https://apps.apple.com/us/app/homecut/id1637394088"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mixedmug.homecut"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>HomeCut - Mobile Application</h3>
              <p>The perfect cut from the comfort of your home</p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>MySQL</li>
                <li>Laravel</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://apps.apple.com/us/app/srs-leb/id1635985870"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.eliechaaban.srcleb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>SRS Leb - Mobile Application</h3>
              <p>Official application for Saint Roch School in Lebanon.</p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>React Native</li>
                <li>Firebase RealTime DB</li>
                <li>Firebase Cloud Functions</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://apps.apple.com/lb/app/imca-consultancy/id1571473789"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleIcon} alt="Download iOS Application" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.eliechaaban.imca"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={androidIcon} alt="Download Android Application" />
                </a>
                <a
                  href="https://appgallery.huawei.com/app/C106853147"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={huaweiIcon} alt="Download Huawei Application" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>IMCA Consultancy - Mobile Application</h3>
              <p>
                Latest Canadian Immigration News and Draws at your fingertips,
                with notifications, so you do not miss the important news
                headlines.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React Native</li>
                <li>SQL</li>
                <li>.NET</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
            </header>
            <div className="body">
              <h3>PRO A2Z Services - Wesbite</h3>
              <p>
                Simplifying your international gateway into higher education and
                future careers
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>TailwindCSS</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
            </header>
            <div className="body">
              <h3>ETS SAL - Wesbite</h3>
              <p>
                Providing the Lebanese market with the best solar energy
                products
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>ReactJS</li>
                <li>TailwindCSS</li>
                <li>SQL</li>
                <li>.NET Core</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="http://cwb-lb.org/" target="_blank" rel="noreferrer">
                  <img src={externalLinkIcon} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Cooperation Without Borders - Wesbite</h3>
              <p>
                CWB is a Lebanese non-governmental organization initiated in
                2017 by a group of activists and experts interested in promoting
                inclusive and resilient socio-economic development across the
                country
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML5</li>
                <li>Javascript</li>
                <li>MySql</li>
                <li>CSS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="http://agridev-lb.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>AgriDev - Website</h3>
              <p>
                AGRIDEV is a consultancy firm specialized in developing and
                implementing food security, agriculture and rural development
                projects in the MENA region
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>HTML5</li>
                <li>Javascript</li>
                <li>MySql</li>
                <li>CSS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
