import { Container } from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import { NavHashLink, HashLink } from "react-router-hash-link";
export function Header() {
  function toggleTheme() {
    let html = document.getElementsByTagName("html")[0];
    html.classList.toggle("light");
  }

  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="#home" className="logo">
          <span style={{ color: "#23ce6b" }}>Elie </span>
          <span>Chaaban</span>
        </HashLink>

        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>

        <nav>
          <NavHashLink smooth to="#home">
            Home
          </NavHashLink>
          <NavHashLink smooth to="#about">
            About
          </NavHashLink>
          <NavHashLink smooth to="#portfolio">
            Portfolio
          </NavHashLink>
          <NavHashLink smooth to="#contact">
            Contact
          </NavHashLink>
          {/* <a href={Curriculo} download className='button'>
            CV
          </a> */}
        </nav>
      </Router>
    </Container>
  );
}
